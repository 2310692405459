import React from 'react';

function HomePage() {
    return (
        <div>
            <h2>HomePage placeholder</h2>
        </div>
    );
}

export default HomePage;
