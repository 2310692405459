import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const ConfirmModal = ({ show, message, onConfirm, onCancel }) => {
    return (
        <Modal show={show} onHide={onCancel}>
            <Modal.Header closeButton>
                <Modal.Title>Confirm Action</Modal.Title>
            </Modal.Header>
            <Modal.Body>{message}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onCancel}>Cancel</Button>
                <Button variant="danger" onClick={onConfirm}>Confirm</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmModal;
