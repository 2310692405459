import React from 'react';

function AboutPage() {
    return (
        <div>
            <h2>AboutPage placeholder</h2>
        </div>
    );
}

export default AboutPage;
